import { Linkable } from '@/components/blocks/Linkable';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { HeaderAccount } from '@/components/content/Header/parts/Account';
import { DivalHeaderMobileDrawer } from '@/components/content/Header/parts/DivalMobileDrawer';
import { DivalHeaderNavBar } from '@/components/content/Header/parts/DivalNavBar';
import { HeaderMiniCart } from '@/components/content/Header/parts/MiniCart';
import { HeaderMobileToggleButton } from '@/components/content/Header/parts/MobileToggleButton';
import { headerContainerSX } from '@/components/content/Header/styles/container';
import {
	headerItemLinkSX,
	headerQuickOrderButtonSX,
} from '@/components/content/Header/styles/itemLink';
import QuickOrder from '@/components/content/QuickOrder';
import { useHeader } from '@/data/Content/Header';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { ID } from '@/data/types/Basic';
import { combineSX } from '@/utils/combineSX';
import { ReceiptLong } from '@mui/icons-material';
import {
	Box,
	Container,
	IconButton,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';

export const Header: FC<{
	id: ID;
}> = ({ id }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = useCallback(
		(open?: boolean) => () => {
			setDrawerOpen(open !== undefined ? open : (current) => !current);
		},
		[]
	);
	const { contentItems } = useHeader(id);
	const HeaderLabels = useLocalization('Header');
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [quickOrderOpen, setQuickOrderOpen] = useState(false);
	const { user } = useUser();

	const { logoEspot, headerTopEspot, headerBannerEspot, navEspots } = useMemo(
		() => ({
			logoEspot: contentItems[0],
			headerTopEspot: contentItems[1],
			headerBannerEspot: contentItems[2],
			navEspots: contentItems?.slice(3),
		}),
		[contentItems]
	);
	// TODO Remove this Logic Once we have isPunchOutUser in @self API call
	const router = useNextRouter();
	useMemo(() => {
		if (
			router.query?.personalizationID &&
			router?.query?.resourceName &&
			router?.query?.WCToken &&
			router?.query?.WCTrustedToken &&
			router?.query?.userId &&
			router?.query?.returnURL
		) {
			if (typeof window !== 'undefined') {
				sessionStorage.setItem('returnURL', router?.query?.returnURL.toString());
				router.replace(router.pathname);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isPunchoutUser = useMemo(
		() => (user?.x_isPunchOutUser === 'true' ? true : false),
		[user?.x_isPunchOutUser]
	);

	return (
		<Box component="header">
			<Paper sx={headerContainerSX} elevation={1}>
				<ContentRecommendation
					key={headerTopEspot.emsName}
					id={`${id}-${headerTopEspot.emsName}`}
					properties={headerTopEspot}
				/>
				<Container>
					{!isMobile ? (
						<Box
							sx={{
								position: 'absolute',
								right: 0,
								bottom: '84px',
								height: '60px',
								width: '50%',
								backgroundColor: 'background.dark',
								display: { xs: 'none', md: 'block' },
							}}
						></Box>
					) : null}
					<Stack
						direction="row"
						alignItems={{ xs: 'center', md: 'flex-end' }}
						justifyContent="space-between"
						spacing={{ xs: 1, sm: 2 }}
						pt={{ xs: 1, lg: 1.5 }}
						pb={{ xs: 1, lg: 2 }}
						sx={{ backgroundColor: 'white', position: 'relative' }}
					>
						<HeaderMobileToggleButton toggleDrawer={toggleDrawer} open={drawerOpen} />
						<Stack direction="row" alignItems="center" spacing={2}>
							<DivalHeaderMobileDrawer
								toggleDrawer={toggleDrawer}
								open={drawerOpen}
								navEspots={navEspots}
								id={id}
								setOpen={setQuickOrderOpen}
							/>
							<Linkable href="/" sx={{ ':hover': { textDecoration: 'none' } }}>
								<ContentRecommendation
									key={logoEspot.emsName}
									id={`${id}-${logoEspot.emsName}`}
									properties={logoEspot}
								/>
							</Linkable>
						</Stack>
						{isMobile ? (
							<Stack direction="row" alignItems="center" spacing={1}>
								<HeaderAccount mobileBreakpoint="md" />
								<HeaderMiniCart mobileBreakpoint="md" />
							</Stack>
						) : (
							<Stack
								direction="column"
								spacing={0.5}
								pb={{ md: 2.5, lg: 1.5 }}
								sx={{ display: { xs: 'none', md: 'block' } }}
							>
								{user?.isLoggedIn && !isMobile ? (
									<Typography sx={{ lineHeight: 1 }}>
										{HeaderLabels.AccountPopper.Welcome.t({
											firstName: user?.firstName ?? '',
											lastName: user?.lastName ?? '',
										})}
									</Typography>
								) : null}
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									sx={{
										minWidth: '310px',
										backgroundColor: 'background.dark',
										color: 'white',
										height: '60px',
										pr: 3,
									}}
								>
									<IconButton
										sx={combineSX([headerItemLinkSX, headerQuickOrderButtonSX])}
										onClick={() => setQuickOrderOpen(true)}
									>
										<ReceiptLong />
										<Typography variant="body1">{HeaderLabels.QuickShop.t()}</Typography>
									</IconButton>
									<HeaderAccount mobileBreakpoint="md" />
									<HeaderMiniCart mobileBreakpoint="md" />
								</Stack>
							</Stack>
						)}
					</Stack>
				</Container>
				<QuickOrder open={quickOrderOpen} setOpen={setQuickOrderOpen} />
				<DivalHeaderNavBar navEspots={navEspots} id={id} />
			</Paper>
			{!isPunchoutUser ? (
				<ContentRecommendation
					key={headerBannerEspot.emsName}
					id={`${id}-${headerBannerEspot.emsName}`}
					properties={{ emsName: headerBannerEspot.emsName, showLoadingSpinner: false }}
				/>
			) : null}
		</Box>
	);
};
